import React, { lazy, useState, useEffect, useCallback, useMemo } from "react";

import {
    VictoryChart,
    VictoryLine,
    VictoryAxis,
    VictoryArea,
    VictoryScatter,
    VictoryLegend,
    VictoryTooltip,
    VictoryLabel,
    VictoryVoronoiContainer,
} from "victory";

import WarningIcon from "@mui/icons-material/WarningRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { useWindowDimensions, formatMs, formatChartData } from "utilities";
import { xs, sm, tablet, md, xl } from "breakpoints";

import testTypeErrorIcon from "resources/testTypeError.svg";
import testTypeIcon from "resources/testType.svg";
import collapseIcon from "resources/collapseIcon.svg";
import expandIcon from "resources/expandIcon.svg";

const Card = lazy(() => import("components/Card"));
const CustomTooltip = lazy(() => import("components/CustomTooltip"));
const ChartHeader = lazy(() => import("components/chart/ChartHeader"));
const ChartError = lazy(() => import("components/chart/ChartError"));
const IncidentTooltip = lazy(() => import("components/chart/IncidentTooltip"));

const legendStyle = {
    fill: "#8A8A8A",
    fontFamily: "Montserrat-Regular",
};

const IncidentIcon = ({ x, y }) => {
    return (
        <svg x={x - 12} y={y - 40}>
            <circle cx="12" cy="40" r="5" fill="red" />
            <WarningIcon
                height={24}
                width={24}
                stroke={"#FF9C50"}
                style={{ color: "#FFDA5C" }}
            />
        </svg>
    );
};

const ZoomControl = ({ zoom, inDisabled, outDisabled }) => {
    return (
        <div className="zoom-control">
            <div
                className={`zoom-in ${inDisabled && "disabled"}`}
                onClick={() => !inDisabled && zoom("in")}
            >
                +
            </div>
            <div
                className={`zoom-out ${outDisabled && "disabled"}`}
                onClick={() => !outDisabled && zoom("out")}
            >
                -
            </div>
        </div>
    );
};

const PanControl = ({ pan, disabled: { left, right, up, down } }) => {
    return (
        <>
            <KeyboardArrowUpIcon
                className={"pan up"}
                onClick={() => !up && pan("up")}
            />
            <KeyboardArrowDownIcon
                className={"pan down"}
                onClick={() => !down && pan("down")}
            />
            <KeyboardArrowRightIcon
                className={"pan right"}
                onClick={() => !right && pan("right")}
            />
            <KeyboardArrowLeftIcon
                className={"pan left"}
                onClick={() => !left && pan("left")}
            />
        </>
    );
};

const CustomVictoryTooltip = ({
    labelOpacity,
    customStyle = {},
    customFlyoutStyle = {},
    orientation = "right",
    ...props
}) => {
    if (props.x === undefined || props.y === undefined) return;

    return (
        <VictoryTooltip
            {...props}
            y={props.y + 10}
            orientation={orientation}
            style={{
                fill: "#E0E0E0",
                fontSize: 14,
                fontFamily: "Montserrat-Regular",
                opacity: labelOpacity / 20,
                ...customStyle,
            }}
            pointerLength={14}
            cornerRadius={8}
            flyoutWidth={props.flyoutWidth || 132}
            flyoutHeight={40}
            flyoutStyle={{
                fill: "black",
                stroke: "#707070",
                strokeWidth: 1,
                opacity: labelOpacity / 20,
                ...customFlyoutStyle,
            }}
        />
    );
};

const ChartCard = ({
    readings,
    pressureTest,
    toggleExpanded,
    expanded,
    pressureTestMetaDataItem,
    pressureTestParameter,
    readingTestStart,
    readingTestEnd,
    readingTestFailed,
    readingStabilisationStart,
    chartMode,
    setChartMode,
    comparingData,
    navbarOpen,
    user,
    workspace,
    incidentReadings,
}) => {
    const [labelsEnabled, setLabelsEnabled] = useState(false);
    const [eventsEnabled, setEventsEnabled] = useState(false);

    const [hovered, setHovered] = useState(false);
    const [labelOpacity, setLabelOpacity] = useState(14);
    const [chartWidth, setChartWidth] = useState(null);

    const [chartData, setChartData] = useState(null);
    const [comparingChartData, setComparingChartData] = useState(null);

    let error = null;

    if (
        !readings ||
        !pressureTest ||
        !pressureTestParameter ||
        !readingTestStart ||
        !readingTestEnd
    ) {
        error = { msg: "No Readings Available", icon: testTypeIcon };
    }

    if (!chartData && !error) {
        error = {
            msg: "Chart could not be generated",
            icon: testTypeErrorIcon,
        };
    }

    const { width, height } = useWindowDimensions();
    const updateWidth = useCallback(
        (totalWidth) => {
            let bodyWidth =
                totalWidth -
                6 -
                (navbarOpen ? 256 : 0) -
                (totalWidth >= sm ? 64 : 30);
            if (totalWidth >= xl) {
                setChartWidth(((bodyWidth - 32 * 2) / 3) * 2 + 32 - 16);
            } else {
                setChartWidth(bodyWidth - 16);
            }
        },
        [navbarOpen],
    );

    useEffect(() => {
        updateWidth(window.innerWidth);
    }, [updateWidth]);

    useEffect(() => {
        window.addEventListener("resize", (e) =>
            updateWidth(e.target.innerWidth),
        );

        return () =>
            window.removeEventListener("resize", (e) =>
                updateWidth(e.target.innerWidth),
            );
    }, [updateWidth]);

    const fade = async (fading) => {
        var x = labelOpacity;

        await clearInterval(intervalID);
        intervalID = setInterval(async function () {
            setLabelOpacity(x);

            if (fading) {
                x = x - 2;
                if (x <= -1) {
                    await clearInterval(intervalID);
                    intervalID = null;
                }
            } else {
                x = x + 2;
                if (x >= 15) {
                    await clearInterval(intervalID);
                    intervalID = null;
                }
            }
        }, 30);
    };

    let intervalID = null;
    useEffect(() => {
        if (!intervalID) {
            fade(hovered);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hovered]);

    const testStartTime = Date.parse(readingTestStart?.measuredAt);
    const pressureAtStart = readingTestStart?.measurement;
    const testEndTime = Date.parse(readingTestEnd?.measuredAt);
    const pressureAtEnd = readingTestEnd?.measurement;

    const parsedStartReading = Date.parse(readings[0].x);
    const parsedEndReading = Date.parse(readings[readings.length - 1].x);
    const parsedTimeDelta = parsedEndReading - parsedStartReading;

    const initialDomain = {
        x: [parsedStartReading, parsedEndReading],
        y: [null, null],
    };

    useEffect(() => {
        if (
            !readings ||
            !pressureTest ||
            !readingTestStart ||
            !readingTestEnd
        ) {
            return;
        }

        updateData(initialDomain);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setComparingChartData(
            comparingData &&
                formatChartData(
                    ...comparingData,
                    chartData?.domain ?? initialDomain,
                    null,
                    [],
                    testStartTime,
                ),
        );
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comparingData]);

    const renderFailureDot =
        !pressureTestParameter.testEndOnFailure && readingTestFailed;
    const renderGRMLine =
        renderFailureDot &&
        !!pressureTestMetaDataItem.instrumentGaugeReadableMovement;

    const renderAnalysis =
        chartMode === "analyse" && chartData?.prediction && chartData?.sample;

    const tooltipActive = labelsEnabled && width > tablet && !hovered;

    const zoomPan = (direction) => {
        const zoomXValue = (chartData.domain.x[1] - chartData.domain.x[0]) / 15;
        const zoomYValue = (chartData.domain.y[1] - chartData.domain.y[0]) / 15;

        const newDomain = {
            x: chartData.domain.x,
            y: chartData.domain.y,
        };

        switch (direction) {
            case "out":
                newDomain.x[0] -= zoomXValue;
                newDomain.x[1] += zoomXValue;
                newDomain.y[0] -= zoomYValue;
                newDomain.y[1] += zoomYValue;
                break;
            case "in":
                newDomain.x[0] += zoomXValue;
                newDomain.x[1] -= zoomXValue;
                newDomain.y[0] += zoomYValue;
                newDomain.y[1] -= zoomYValue;
                break;
            case "left":
                newDomain.x[0] -= zoomXValue;
                newDomain.x[1] -= zoomXValue;
                break;
            case "right":
                newDomain.x[0] += zoomXValue;
                newDomain.x[1] += zoomXValue;
                break;
            case "up":
                newDomain.y[0] += zoomYValue;
                newDomain.y[1] += zoomYValue;
                break;
            case "down":
                newDomain.y[0] -= zoomYValue;
                newDomain.y[1] -= zoomYValue;
        }

        updateData(newDomain);
    };

    const updateData = (domain) => {
        setChartData(
            formatChartData(
                readings,
                readingTestStart,
                readingTestEnd,
                readingStabilisationStart,
                domain,
                readingTestFailed,
                incidentReadings,
            ),
        );
        comparingData &&
            setComparingChartData(
                formatChartData(
                    ...comparingData,
                    domain,
                    null,
                    [],
                    testStartTime,
                ),
            );
    };

    if (error) {
        return <ChartError error={error} />;
    } else {
        return (
            <div className="chart">
                <Card>
                    <div className="chart-card">
                        <ChartHeader
                            labels={labelsEnabled}
                            setLabels={setLabelsEnabled}
                            eventsDisabled={!chartData?.events?.length}
                            events={eventsEnabled}
                            setEvents={setEventsEnabled}
                            analyseDisabled={
                                !(
                                    readingStabilisationStart &&
                                    chartData?.sample &&
                                    chartData?.prediction &&
                                    workspace?.workspaceUserRole === "manager"
                                )
                            }
                            expanded={expanded}
                            toggleExpanded={toggleExpanded}
                            mode={chartMode}
                            setMode={setChartMode}
                            user={user}
                        />
                        <ZoomControl
                            zoom={(dir) => zoomPan(dir)}
                            inDisabled={chartData.data.length < 10}
                            outDisabled={
                                chartData.domain.x[0] <= parsedStartReading
                            }
                        />
                        <PanControl
                            pan={(dir) => zoomPan(dir)}
                            disabled={{
                                left:
                                    chartData.domain.x[0] <= parsedStartReading,
                                right:
                                    chartData.domain.x[1] >= parsedEndReading,
                                up:
                                    chartData.domain.y[1] >=
                                    chartData.maxY * 1.1,
                                down: chartData.domain.y[0] <= 0,
                            }}
                        />
                        <VictoryChart
                            padding={{
                                top: 10,
                                bottom: 30,
                                left: 52,
                                right: 20,
                            }}
                            domainPadding={{
                                y: width > sm ? 60 : 20,
                                x: [0, 5],
                            }}
                            width={chartWidth}
                            height={
                                width >= xl
                                    ? 618
                                    : width >= md
                                      ? 520
                                      : width >= 820
                                        ? 360
                                        : 280
                            }
                            domain={chartData.domain}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    voronoiBlacklist={[
                                        "scatter",
                                        "pressureAtStart",
                                        "pressureAtStartDot",
                                        "pressureAtStartY",
                                        "pressureAtStartYDot",
                                        "pressureAtEnd",
                                        "pressureAtEndDot",
                                        "pressureAtFailure",
                                        "pressureAtFailureDot",
                                        "GRMDot",
                                        "GRMLine",
                                        "pressureAtStabilisationStart",
                                        "pressureAtStabilisationStartDot",
                                        "fullArea",
                                        "testArea",
                                        "decrease",
                                        "increase",
                                        "hiddenArea",
                                        "decreaseDot",
                                        "increaseDot",
                                        "yHighPadding",
                                        "yLowPadding",
                                        "comparingPressureAtStabilisationVertical",
                                        "comparingPressureAtStartVertical",
                                        "comparingPressureAtEndVertical",
                                        "comparingFullArea",
                                        "compringTestArea",
                                        "comparingScatter",
                                    ]}
                                />
                            }
                        >
                            {renderAnalysis && (
                                <VictoryLegend
                                    x={66}
                                    y={10}
                                    orientation="horizontal"
                                    gutter={30}
                                    data={[
                                        {
                                            name: "Sample",
                                            symbol: { fill: "#59CAA5" },
                                            labels: legendStyle,
                                        },
                                        {
                                            name: "Prediction",
                                            symbol: { fill: "#9368B7" },
                                            labels: legendStyle,
                                        },
                                    ]}
                                />
                            )}
                            <VictoryAxis
                                style={{
                                    axis: { stroke: 0 },
                                    grid: { stroke: "#F3F3F3" },
                                }}
                                tickFormat={(t) => null}
                            />
                            <VictoryAxis
                                style={{
                                    axis: { stroke: 0 },
                                    grid: { stroke: "#F3F3F3" },
                                }}
                                tickFormat={(t) => null}
                                dependentAxis
                            />
                            <VictoryAxis
                                crossAxis={false}
                                style={{
                                    axis: { stroke: "#8D8D8D" },
                                    tickLabels: {
                                        fontSize: 14,
                                        padding: 5,
                                        fontFamily: "Montserrat-Regular",
                                        fill: "#707070",
                                        display: `${
                                            chartWidth < xs ? "none" : "block"
                                        }`,
                                    },
                                }}
                                tickFormat={(t) => formatMs(t)}
                                tickCount={chartWidth > xs ? 6 : 0}
                            />
                            <VictoryAxis
                                style={{
                                    axis: { stroke: "#8D8D8D" },
                                    tickLabels: {
                                        fontSize: 14,
                                        padding: 5,
                                        fontFamily: "Montserrat-Regular",
                                        fill: "#707070",
                                    },
                                }}
                                dependentAxis
                            />
                            <VictoryLine
                                style={{
                                    data: {
                                        stroke: "#FF4351",
                                        strokeWidth: 3,
                                    },
                                }}
                                data={chartData.data}
                                labels={() => ""}
                                labelComponent={
                                    labelsEnabled && width > tablet ? (
                                        <CustomTooltip
                                            startPressure={pressureAtStart}
                                            opacity={(14 - labelOpacity) / 20}
                                            decimalPlaces={
                                                user.superuser ? 5 : 2
                                            }
                                        />
                                    ) : (
                                        <VictoryLabel />
                                    )
                                }
                            />
                            <VictoryScatter
                                style={{
                                    data: {
                                        opacity: 0,
                                    },
                                }}
                                active={
                                    !(
                                        testStartTime < chartData.domain.x[0] ||
                                        testStartTime > chartData.domain.x[1]
                                    )
                                }
                                name="pressureAtStartDot"
                                data={[
                                    {
                                        x: testStartTime,
                                        y:
                                            (2 *
                                                (chartData.domain.y[1] >
                                                pressureAtStart
                                                    ? pressureAtStart
                                                    : chartData.domain.y[1])) /
                                            3,
                                    },
                                ]}
                                labels={() => ""}
                                labelComponent={
                                    <CustomVictoryTooltip
                                        active={tooltipActive}
                                        text={"Test Period Start"}
                                        labelOpacity={labelOpacity}
                                    />
                                }
                            />
                            <VictoryLine
                                name="pressureAtStart"
                                style={{
                                    data: {
                                        stroke: "#FF4351",
                                        strokeDasharray: "8, 5",
                                    },
                                }}
                                data={[
                                    { x: testStartTime, y: 0 },
                                    {
                                        x: testStartTime,
                                        y: pressureAtStart,
                                    },
                                ]}
                            />
                            {readingStabilisationStart && (
                                <VictoryLine
                                    name="pressureAtStabilisationStart"
                                    style={{
                                        data: {
                                            stroke: "#FF4351",
                                            strokeDasharray: "8, 5",
                                        },
                                    }}
                                    data={[
                                        {
                                            x: chartData.stabilisationDot.x,
                                            y: 0,
                                        },
                                        chartData.stabilisationDot,
                                    ]}
                                />
                            )}
                            {readingStabilisationStart && (
                                <VictoryScatter
                                    style={{
                                        data: {
                                            opacity: 0,
                                        },
                                    }}
                                    name="pressureAtStabilisationStartDot"
                                    data={[
                                        {
                                            x: chartData.stabilisationDot.x,
                                            y:
                                                readingStabilisationStart.measurement /
                                                4,
                                        },
                                    ]}
                                    labels={() => ""}
                                    labelComponent={
                                        <CustomVictoryTooltip
                                            active={tooltipActive}
                                            text={"Stabilisation Period Start"}
                                            labelOpacity={labelOpacity}
                                            flyoutWidth={196}
                                        />
                                    }
                                />
                            )}
                            <VictoryScatter
                                style={{
                                    data: {
                                        opacity: 0,
                                    },
                                }}
                                name="pressureAtEndDot"
                                data={[
                                    {
                                        x: testEndTime,
                                        y: pressureAtEnd / 3,
                                    },
                                ]}
                                labels={() => ""}
                                labelComponent={
                                    <CustomVictoryTooltip
                                        active={tooltipActive}
                                        orientation="left"
                                        text={"Test Period End"}
                                        labelOpacity={labelOpacity}
                                        flyoutWidth={126}
                                    />
                                }
                            />
                            {renderFailureDot && (
                                <VictoryScatter
                                    style={{
                                        data: {
                                            opacity: 1,
                                            fill: "red",
                                        },
                                    }}
                                    name="pressureAtFailureDot"
                                    data={[
                                        {
                                            ...chartData.testFailedDot,
                                            size: 5,
                                        },
                                    ]}
                                    labels={() => ""}
                                />
                            )}
                            {renderGRMLine && (
                                <VictoryScatter
                                    style={{
                                        data: {
                                            opacity: 0,
                                            fill: "red",
                                        },
                                    }}
                                    name="GRMDot"
                                    data={[
                                        {
                                            x:
                                                parsedStartReading +
                                                parsedTimeDelta / 2,
                                            y:
                                                pressureTestParameter.testAllowablePressureIncrease !==
                                                null
                                                    ? pressureAtStart +
                                                      pressureTestParameter.testAllowablePressureIncrease +
                                                      pressureTestMetaDataItem.instrumentGaugeReadableMovement
                                                    : pressureAtStart -
                                                      pressureTestParameter.testAllowablePressureDecrease -
                                                      pressureTestMetaDataItem.instrumentGaugeReadableMovement,
                                            size: 5,
                                        },
                                    ]}
                                    labels={() => ""}
                                    labelComponent={
                                        <CustomVictoryTooltip
                                            active={tooltipActive}
                                            orientation="bottom"
                                            text={
                                                pressureTestParameter.testAllowablePressureIncrease !==
                                                null
                                                    ? "Allowable Increase + GRM"
                                                    : "Allowable Decrease - GRM"
                                            }
                                            labelOpacity={labelOpacity}
                                            flyoutWidth={200}
                                        />
                                    }
                                />
                            )}
                            {renderFailureDot && (
                                <VictoryLine
                                    name="pressureAtFailure"
                                    style={{
                                        data: {
                                            stroke: "red",
                                            strokeDasharray: "8, 5",
                                        },
                                    }}
                                    data={[
                                        { x: chartData.testFailedDot.x, y: 0 },
                                        chartData.testFailedDot,
                                    ]}
                                />
                            )}
                            <VictoryLine
                                name="pressureAtEnd"
                                style={{
                                    data: {
                                        stroke: "#FF4351",
                                        strokeDasharray: "8, 5",
                                    },
                                }}
                                data={[
                                    { x: chartData.testEndDot.x, y: 0 },
                                    chartData.testEndDot,
                                ]}
                            />
                            <VictoryArea
                                name="fullArea"
                                style={{
                                    data: {
                                        fill: "rgba(255,67,81,0.1)",
                                    },
                                }}
                                data={chartData.shadedData}
                            />
                            <VictoryArea
                                name="testArea"
                                style={{
                                    data: {
                                        fill: "rgba(255,67,81,0.3)",
                                    },
                                }}
                                data={chartData.testPeriodData}
                            />
                            <VictoryScatter
                                style={{
                                    data: {
                                        opacity: 0,
                                    },
                                }}
                                name="pressureAtStartYDot"
                                data={[
                                    {
                                        x:
                                            parsedStartReading +
                                            parsedTimeDelta / 4,
                                        y: pressureAtStart,
                                    },
                                ]}
                                labels={() => ""}
                                labelComponent={
                                    <CustomVictoryTooltip
                                        active={tooltipActive}
                                        orientation="bottom"
                                        text={
                                            "Pressure at Start of Test Period"
                                        }
                                        labelOpacity={labelOpacity}
                                        flyoutWidth={230}
                                    />
                                }
                            />
                            <VictoryLine
                                name="pressureAtStartY"
                                style={{
                                    data: {
                                        stroke: "#FF4351",
                                        strokeDasharray: "8, 5",
                                    },
                                }}
                                y={() => pressureAtStart}
                            />
                            <VictoryScatter
                                name="scatter"
                                style={{ data: { fill: "#FF4351" } }}
                                data={[
                                    { ...chartData.testStartDot, size: 5 },
                                    { ...chartData.testEndDot, size: 5 },
                                    chartData.stabilisationDot && {
                                        ...chartData.stabilisationDot,
                                        size: 5,
                                    },
                                ]}
                            />
                            {pressureTestParameter.testAllowablePressureDecrease !==
                                null && (
                                <VictoryLine
                                    name="decrease"
                                    style={{
                                        data: {
                                            stroke: "#FF4351",
                                            strokeDasharray: "8, 5",
                                        },
                                    }}
                                    y={() =>
                                        pressureAtStart -
                                        pressureTestParameter.testAllowablePressureDecrease
                                    }
                                />
                            )}
                            {renderGRMLine && (
                                <VictoryLine
                                    name={"GRMLine"}
                                    style={{
                                        data: {
                                            stroke: "red",
                                            strokeDasharray: "8, 5",
                                        },
                                    }}
                                    y={() =>
                                        pressureTestParameter.testAllowablePressureIncrease !==
                                        null
                                            ? pressureAtStart +
                                              pressureTestParameter.testAllowablePressureIncrease +
                                              pressureTestMetaDataItem.instrumentGaugeReadableMovement
                                            : pressureAtStart -
                                              pressureTestParameter.testAllowablePressureDecrease -
                                              pressureTestMetaDataItem.instrumentGaugeReadableMovement
                                    }
                                />
                            )}
                            {pressureTestParameter.testAllowablePressureDecrease !==
                                null && (
                                <VictoryScatter
                                    style={{
                                        data: {
                                            opacity: 0,
                                        },
                                    }}
                                    name="decreaseDot"
                                    data={[
                                        {
                                            x:
                                                pressureTestParameter.testAllowablePressureIncrease !==
                                                    null ||
                                                !chartData.testPeriodData.length
                                                    ? parsedStartReading +
                                                      parsedTimeDelta / 2
                                                    : chartData.testPeriodData[
                                                          Math.floor(
                                                              chartData
                                                                  .testPeriodData
                                                                  .length / 2,
                                                          )
                                                      ].x,
                                            y:
                                                pressureAtStart -
                                                pressureTestParameter.testAllowablePressureDecrease,
                                        },
                                    ]}
                                    labels={() => ""}
                                    labelComponent={
                                        <CustomVictoryTooltip
                                            active={tooltipActive}
                                            orientation={
                                                pressureAtStart -
                                                    pressureTestParameter.testAllowablePressureDecrease <
                                                (2 *
                                                    (chartData.domain.y[1] ||
                                                        pressureAtStart)) /
                                                    3
                                                    ? "bottom"
                                                    : "top"
                                            }
                                            text={"Allowable Pressure Decrease"}
                                            labelOpacity={labelOpacity}
                                            flyoutWidth={218}
                                        />
                                    }
                                />
                            )}
                            {pressureTestParameter.testAllowablePressureIncrease !==
                                null && (
                                <VictoryLine
                                    name="increase"
                                    style={{
                                        data: {
                                            stroke: "#FF4351",
                                            strokeDasharray: "8, 5",
                                        },
                                    }}
                                    y={() =>
                                        pressureAtStart +
                                        pressureTestParameter.testAllowablePressureIncrease
                                    }
                                />
                            )}
                            {pressureTestParameter.testAllowablePressureIncrease !==
                                null && (
                                <VictoryScatter
                                    style={{
                                        data: {
                                            opacity: 0,
                                        },
                                    }}
                                    name="increaseDot"
                                    data={[
                                        {
                                            x:
                                                pressureTestParameter.testAllowablePressureDecrease !==
                                                    null ||
                                                !chartData.testPeriodData.length
                                                    ? parsedStartReading +
                                                      (3 * parsedTimeDelta) / 4
                                                    : chartData.testPeriodData[
                                                          Math.floor(
                                                              chartData
                                                                  .testPeriodData
                                                                  .length / 2,
                                                          )
                                                      ].x,
                                            y:
                                                pressureAtStart +
                                                pressureTestParameter.testAllowablePressureIncrease,
                                        },
                                    ]}
                                    labels={() => ""}
                                    labelComponent={
                                        <CustomVictoryTooltip
                                            active={tooltipActive}
                                            orientation={
                                                pressureAtStart +
                                                    pressureTestParameter.testAllowablePressureIncrease <
                                                pressureAtEnd / 3
                                                    ? "bottom"
                                                    : "top"
                                            }
                                            text={"Allowable Pressure Increase"}
                                            labelOpacity={labelOpacity}
                                            flyoutWidth={218}
                                        />
                                    }
                                />
                            )}
                            {renderAnalysis && (
                                <VictoryLine
                                    name="prediction"
                                    style={{
                                        data: {
                                            stroke: "#9368B7",
                                            strokeWidth: 3,
                                        },
                                    }}
                                    data={[
                                        chartData.testStartDot,
                                        {
                                            x: chartData.testEndDot.x,
                                            y: chartData.prediction,
                                        },
                                    ]}
                                />
                            )}
                            {renderAnalysis && (
                                <VictoryScatter
                                    style={{
                                        data: {
                                            opacity: 0,
                                        },
                                    }}
                                    name="predictionDot"
                                    data={[
                                        {
                                            x:
                                                (chartData.testStartDot.x +
                                                    chartData.testEndDot.x) /
                                                2,
                                            y:
                                                chartData.testStartDot.y +
                                                (chartData.prediction -
                                                    chartData.testStartDot.y) /
                                                    2,
                                        },
                                    ]}
                                    labels={() => ""}
                                    labelComponent={
                                        <CustomVictoryTooltip
                                            active
                                            orientation="bottom"
                                            customStyle={{
                                                fill: "#E0E0E0",
                                                fontSize: 14,
                                                opacity: 0.7,
                                                fontFamily:
                                                    "Montserrat-Regular",
                                            }}
                                            text={`Expected final pressure ${chartData.prediction.toFixed(
                                                2,
                                            )} mbar`}
                                            customFlyoutStyle={{
                                                fill: "black",
                                                stroke: "#707070",
                                                strokeWidth: 1,
                                                opacity: 0.7,
                                            }}
                                            labelOpacity={labelOpacity}
                                            flyoutWidth={265}
                                        />
                                    }
                                />
                            )}
                            {renderAnalysis && (
                                <VictoryLine
                                    name="sample"
                                    style={{
                                        data: {
                                            stroke: "#59CAA5",
                                            strokeWidth: 3,
                                        },
                                    }}
                                    data={[
                                        chartData.sample.start,
                                        chartData.sample.end,
                                    ]}
                                />
                            )}
                            {renderAnalysis && (
                                <VictoryScatter
                                    style={{
                                        data: {
                                            opacity: 0,
                                        },
                                    }}
                                    name="sampleDot"
                                    data={[
                                        {
                                            x:
                                                (chartData.sample.start.x +
                                                    chartData.sample.end.x) /
                                                2,
                                            y:
                                                chartData.sample.start.y +
                                                (chartData.sample.end.y -
                                                    chartData.sample.start.y) /
                                                    2,
                                        },
                                    ]}
                                    labels={() => ""}
                                    labelComponent={
                                        <CustomVictoryTooltip
                                            active
                                            orientation="bottom"
                                            customStyle={{
                                                fill: "#E0E0E0",
                                                fontSize: 14,
                                                opacity: 0.7,
                                                fontFamily:
                                                    "Montserrat-Regular",
                                            }}
                                            text={`${(
                                                chartData.sample.gradient *
                                                60000
                                            ).toFixed(2)} mbar/min`}
                                            customFlyoutStyle={{
                                                fill: "black",
                                                stroke: "#707070",
                                                strokeWidth: 1,
                                                opacity: 0.7,
                                            }}
                                            labelOpacity={labelOpacity}
                                            flyoutWidth={130}
                                        />
                                    }
                                />
                            )}
                            {eventsEnabled && (
                                <VictoryScatter
                                    style={{ data: { fill: "#FF4351" } }}
                                    name="events"
                                    data={chartData.events}
                                    labels={() => ""}
                                    dataComponent={<IncidentIcon />}
                                    labelComponent={
                                        <IncidentTooltip
                                            startPressure={pressureAtStart}
                                            decimalPlaces={
                                                user.superuser ? 5 : 2
                                            }
                                        />
                                    }
                                />
                            )}
                            <VictoryLine
                                style={{
                                    data: {
                                        opacity: 0,
                                    },
                                }}
                                name="yHighPadding"
                                data={chartData.data}
                                y={(d) => d.y + chartData.fractionalData}
                            />
                            <VictoryLine
                                style={{
                                    data: {
                                        opacity: 0,
                                    },
                                }}
                                name="yLowPadding"
                                data={chartData.data}
                                y={(d) => d.y - chartData.fractionalData}
                            />
                            {labelsEnabled && (
                                <VictoryArea
                                    name="hiddenArea"
                                    style={{
                                        data: {
                                            opacity: 0,
                                        },
                                    }}
                                    data={chartData.data}
                                    y={(d) => d.y + chartData.fractionalData}
                                    y0={(d) => d.y - chartData.fractionalData}
                                    events={[
                                        {
                                            target: "parent",
                                            eventHandlers: {
                                                onMouseEnter: async () => {
                                                    if (!hovered) {
                                                        await clearInterval(
                                                            intervalID,
                                                        );
                                                        intervalID = null;
                                                        setHovered(true);
                                                    }
                                                },
                                                onMouseLeave: async () => {
                                                    if (hovered) {
                                                        await clearInterval(
                                                            intervalID,
                                                        );
                                                        intervalID = null;
                                                        setHovered(false);
                                                    }
                                                },
                                            },
                                        },
                                    ]}
                                />
                            )}
                            {comparingChartData && (
                                <VictoryLine
                                    style={{
                                        data: {
                                            stroke: "#9368B7",
                                            strokeWidth: 3,
                                        },
                                    }}
                                    data={comparingChartData.data}
                                />
                            )}
                            {comparingChartData?.stabilisationDot && (
                                <VictoryLine
                                    name="comparingPressureAtStabilisationVertical"
                                    style={{
                                        data: {
                                            stroke: "#9368B7",
                                            strokeDasharray: "8, 5",
                                        },
                                    }}
                                    data={[
                                        {
                                            x: comparingChartData
                                                .stabilisationDot.x,
                                            y: 0,
                                        },
                                        comparingChartData.stabilisationDot,
                                    ]}
                                />
                            )}
                            {comparingChartData && (
                                <VictoryLine
                                    name="comparingPressureAtStartVertical"
                                    style={{
                                        data: {
                                            stroke: "#9368B7",
                                            strokeDasharray: "8, 5",
                                        },
                                    }}
                                    data={[
                                        {
                                            x: comparingChartData.testStartDot
                                                .x,
                                            y: 0,
                                        },
                                        comparingChartData.testStartDot,
                                    ]}
                                />
                            )}
                            {comparingChartData && (
                                <VictoryLine
                                    name="comparingPressureAtEndVertical"
                                    style={{
                                        data: {
                                            stroke: "#9368B7",
                                            strokeDasharray: "8, 5",
                                        },
                                    }}
                                    data={[
                                        {
                                            x: comparingChartData.testEndDot.x,
                                            y: 0,
                                        },
                                        comparingChartData.testEndDot,
                                    ]}
                                />
                            )}
                            {comparingChartData && (
                                <VictoryArea
                                    name="comparingFullArea"
                                    style={{
                                        data: {
                                            fill: "rgba(147, 104, 183, 0.2)",
                                        },
                                    }}
                                    data={comparingChartData.shadedData}
                                />
                            )}
                            {comparingChartData && (
                                <VictoryArea
                                    name="compringTestArea"
                                    style={{
                                        data: {
                                            fill: "rgba(147, 104, 183, 0.4)",
                                        },
                                    }}
                                    data={comparingChartData.testPeriodData}
                                />
                            )}
                            {comparingChartData && (
                                <VictoryScatter
                                    name="comparingScatter"
                                    style={{ data: { fill: "#9368B7" } }}
                                    data={[
                                        {
                                            ...comparingChartData.testStartDot,
                                            size: 5,
                                        },
                                        {
                                            ...comparingChartData.testEndDot,
                                            size: 5,
                                        },
                                        comparingChartData.stabilisationDot && {
                                            ...comparingChartData.stabilisationDot,
                                            size: 5,
                                        },
                                    ]}
                                />
                            )}
                        </VictoryChart>
                    </div>
                </Card>
            </div>
        );
    }
};

export default ChartCard;
