import React, { Component, Suspense, lazy } from "react";

import {
    BrowserRouter,
    Route,
    Redirect,
    withRouter,
    Switch,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import ReactMarkdown from "react-markdown";

import { Navigation, Header, Loader } from "components";
import CustomSnackbar from "components/CustomSnackbar";
import { Activity } from "pages";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import SmartesterAPI from "context/SmartesterAPI";
import { SmartesterAPIProvider } from "context/SmartesterAPIContext";
import SamlProvider from "context/SamlProvider";

import "layouts/App.scss";

import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import { tablet } from "breakpoints";
import { toCamelCase } from "utilities";

import QueryString from "query-string";
const Forbidden = lazy(() => import("pages/Forbidden"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const InviteToken = lazy(() => import("pages/InviteToken"));
const Jobs = lazy(() => import("pages/Jobs"));
const Login = lazy(() => import("pages/Login"));
const PageNotFound = lazy(() => import("pages/PageNotFound"));
const Profile = lazy(() => import("pages/Profile"));
const ProfileDelete = lazy(() => import("pages/ProfileDelete"));
const ProfileDeleted = lazy(() => import("pages/ProfileDeleted"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const SignUp = lazy(() => import("pages/SignUp"));
const Verify = lazy(() => import("pages/Verify"));
const WorkspaceUser = lazy(() => import("pages/WorkspaceUser"));
const WorkspaceUsers = lazy(() => import("pages/WorkspaceUsers"));
const Smartesters = lazy(() => import("pages/Smartesters"));
const Smartester = lazy(() => import("pages/Smartester"));
const AcceptableUsePolicy = lazy(() => import("pages/AcceptableUsePolicy"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));

const api = SmartesterAPI;

const theme = createTheme({
    palette: {
        primary: {
            main: "#FF4351",
        },
        secondary: {
            main: "#363636",
            light: "#484848",
        },
    },
});

class App extends Component {
    constructor(props) {
        super(props);

        this.history = createBrowserHistory();

        var navClosed =
            JSON.parse(window.localStorage.getItem("navClosed")) || null;
        var navClosedUser = JSON.parse(
            window.localStorage.getItem("navClosedUser"),
        );

        this.state = {
            isLoading: true,
            user: false,
            navClosed: navClosed,
            navClosedUser: navClosedUser,
            error: false,
            loadingAgreements: false,
            outstandingAgreements: [],
            visibleAgreements: {},
            agreementVersions: {},
        };

        SmartesterAPI.on("loggedin", (user) => {
            this.setUser(user);
            this.checkAgreements(user);
        });
        SmartesterAPI.on("loggedout", () => this.setNoUser());
        SmartesterAPI.on(
            "forceloggedout",
            () => this.state.user && this.setForceLogout(),
        );
    }

    checkAgreements(user) {
        return SmartesterAPI.getAgreements()
            .then((response) => {
                const outstandingAgreements = [];
                response.data.forEach((a) => {
                    if (user[toCamelCase(a.permalink)] < a.version) {
                        outstandingAgreements.push(a);
                    }
                });

                this.setState({ outstandingAgreements });
            })
            .catch(() => this.setState({ outstandingAgreements: [] }));
    }

    componentDidMount() {
        SmartesterAPI.getLoggedInUser()
            .then((user) => this.checkAgreements(user))
            .catch(() => this.setNoUser());
    }

    setVisibility(agreement) {
        const visibleAgreements = { ...this.state.visibleAgreements };
        visibleAgreements[agreement] = !Boolean(visibleAgreements[agreement]);
        this.setState({ visibleAgreements });
    }

    changeAgreementVersion(agreement, version) {
        const agreementVersions = { ...this.state.agreementVersions };
        if (version) {
            agreementVersions[agreement] = version;
        } else {
            delete agreementVersions[agreement];
        }
        this.setState({ agreementVersions });
    }

    submitAgreements() {
        this.setState({ loadingAgreements: true });
        SmartesterAPI.updateProfile({ user: this.state.agreementVersions })
            .then((user) =>
                this.setState({
                    user,
                    outstandingAgreements: [],
                    loadingAgreements: false,
                }),
            )
            .catch(() =>
                this.setState({
                    error: "Sorry, the service is currently unavailable. Please try again",
                    loadingAgreements: false,
                }),
            );
    }

    toggleNav() {
        localStorage.setItem(
            "navClosed",
            JSON.stringify(!this.state.navClosed),
        );
        this.setState((prevState) => ({
            navClosed: !prevState.navClosed,
        }));
    }
    //side menu - false=open, true=closed,  null=widescreen/open & narrowscreen/closed. Facilitates sidemenu closes when screen shrinks, even if it was open on widescreen
    toggleNavUser = (width) => {
        var newValue = null;
        if (width > tablet) {
            if (
                this.state.navClosedUser === false ||
                this.state.navClosedUser === null
            ) {
                newValue = true;
            } else {
                if (this.state.navClosedUser === true) {
                    newValue = null;
                }
            }
        } else {
            if (
                this.state.navClosedUser === true ||
                this.state.navClosedUser === null
            ) {
                newValue = false;
            } else {
                if (this.state.navClosedUser === false) {
                    newValue = null;
                }
            }
        }
        localStorage.setItem("navClosedUser", JSON.stringify(newValue));
        this.setState({ navClosedUser: newValue });
    };

    setUser = (user) => {
        return this.setState({ isLoading: false, user: user });
    };

    setNoUser = () => {
        return this.setState({ isLoading: false, user: false });
    };

    setForceLogout = () => {
        return this.setState({
            isLoading: false,
            user: false,
            error: "Your session has ended and you need to login again to continue",
        });
    };

    clearError = () => this.setState({ error: false });

    updateHeader = () => {
        SmartesterAPI.getLoggedInUser();
    };

    HeaderWithRouter = withRouter(Header);
    NavigationWithRouter = withRouter(Navigation);

    getComponent = (shouldRedirect, Component, props, permitted = true) => {
        const currentPath = props.match.url;

        return shouldRedirect ? (
            this.state.user && !this.state.user["verified?"] ? (
                <Redirect
                    to={{
                        pathname: "/verify",
                    }}
                />
            ) : (
                <Redirect
                    to={{
                        pathname:
                            currentPath === "/login" ||
                            currentPath === "/forgotten-password" ||
                            currentPath === "/sign-up" ||
                            currentPath === "/reset-password"
                                ? QueryString.parse(props.location.search)
                                      .redirect
                                : "/login",
                        search:
                            currentPath === "/login" ||
                            currentPath === "/" ||
                            currentPath === "/forgotten-password" ||
                            currentPath === "/sign-up" ||
                            currentPath === "/reset-password"
                                ? ""
                                : `?redirect=${currentPath}`,
                        state: {
                            token:
                                QueryString.parse(props.location.search)
                                    .token || props.location.state?.token,
                        },
                    }}
                />
            )
        ) : currentPath === "/login" ||
          currentPath === "/forgotten-password" ||
          currentPath === "/sign-up" ||
          currentPath === "/reset-password" ||
          !(this.state.user && this.state.user["verified?"]) ? (
            <Component {...props} user={this.state.user} />
        ) : permitted ? (
            Object.keys(SmartesterAPI.getCurrentWorkspace()).length === 0 &&
            currentPath !== "/profile" &&
            currentPath !== "/profile/delete" ? (
                <Redirect
                    to={{
                        pathname: "/profile",
                    }}
                />
            ) : (
                <div
                    className={`content ${
                        (
                            this.state.navClosedUser !== null
                                ? this.state.navClosedUser
                                : this.state.navClosed
                        )
                            ? "no-nav"
                            : ""
                    }`}
                >
                    <Component {...props} user={this.state.user} />
                </div>
            )
        ) : (
            <div
                className={`content ${
                    (
                        this.state.navClosedUser !== null
                            ? this.state.navClosedUser
                            : this.state.navClosed
                    )
                        ? "no-nav"
                        : ""
                }`}
            >
                <Forbidden path={currentPath} {...props} />
            </div>
        );
    };

    renderRoutes() {
        const hasUser = this.state.user;
        const currentWorkspace = SmartesterAPI.getCurrentWorkspace();

        const isManager = currentWorkspace?.workspaceUserRole === "manager";
        document.title = "SMARTester Portal";

        return (
            <SmartesterAPIProvider value={api}>
                <ThemeProvider theme={theme}>
                    <SamlProvider>
                        <BrowserRouter basename={"/"}>
                            <QueryParamProvider
                                adapter={ReactRouter5Adapter}
                                options={{
                                    searchStringToObject: QueryString.parse,
                                    objectToSearchString: QueryString.stringify,
                                }}
                            >
                                <>
                                    {hasUser && hasUser["verified?"] && (
                                        <>
                                            <this.NavigationWithRouter
                                                user={this.state.user}
                                                navClosed={this.state.navClosed}
                                                navClosedUser={
                                                    this.state.navClosedUser
                                                }
                                            />
                                            <this.HeaderWithRouter
                                                user={this.state.user}
                                                toggleNav={() =>
                                                    this.toggleNav()
                                                }
                                                toggleNavUser={(width) =>
                                                    this.toggleNavUser(width)
                                                }
                                                navClosed={this.state.navClosed}
                                                navClosedUser={
                                                    this.state.navClosedUser
                                                }
                                            />
                                        </>
                                    )}
                                    <Suspense fallback={<Loader />}>
                                        <Switch>
                                            <Route
                                                path="/login"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        hasUser,
                                                        Login,
                                                        props,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/forgotten-password"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        hasUser,
                                                        ForgotPassword,
                                                        props,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/reset-password"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        hasUser,
                                                        ResetPassword,
                                                        props,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/sign-up"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        hasUser,
                                                        SignUp,
                                                        props,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/privacy-policy"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        false,
                                                        PrivacyPolicy,
                                                        props,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/acceptable-use-policy"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        false,
                                                        AcceptableUsePolicy,
                                                        props,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/deleted"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        hasUser,
                                                        ProfileDeleted,
                                                        {
                                                            navClosed:
                                                                this.state
                                                                    .navClosed,
                                                            navClosedUser:
                                                                this.state
                                                                    .navClosedUser,
                                                            toggleNav: () =>
                                                                this.toggleNav(),
                                                            toggleNavUser: (
                                                                width,
                                                            ) =>
                                                                this.toggleNavUser(
                                                                    width,
                                                                ),
                                                            ...props,
                                                        },
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/verify"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            !hasUser[
                                                                "verified?"
                                                            ]
                                                        ),
                                                        Verify,
                                                        props,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/jobs"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        Jobs,
                                                        props,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/organisations/:id"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        WorkspaceUsers,
                                                        props,
                                                        isManager,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/workspace-users"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        WorkspaceUsers,
                                                        props,
                                                        isManager,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/workspace-users/:id"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        WorkspaceUser,
                                                        props,
                                                        isManager,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/smartesters"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        Smartesters,
                                                        props,
                                                        isManager &&
                                                            currentWorkspace?.workspaceUserOrganisationManageSmartestersEnabled,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/smartesters/:id"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        Smartester,
                                                        props,
                                                        isManager &&
                                                            currentWorkspace?.workspaceUserOrganisationManageSmartestersEnabled,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/invite-tokens/:id"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        InviteToken,
                                                        props,
                                                        isManager,
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/profile/delete"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        ProfileDelete,
                                                        {
                                                            clearUser: () =>
                                                                this.setNoUser(),
                                                            ...props,
                                                        },
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/profile"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        Profile,
                                                        {
                                                            updateHeader:
                                                                this
                                                                    .updateHeader,
                                                            ...props,
                                                        },
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/"
                                                exact
                                                render={(props) => (
                                                    <Redirect to={"/jobs"} />
                                                )}
                                            />
                                            <Route
                                                path="/activities/:id"
                                                exact
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        Activity,
                                                        {
                                                            user: this.state
                                                                .user,
                                                            navbarOpen: !(this
                                                                .state
                                                                .navClosedUser !==
                                                            null
                                                                ? this.state
                                                                      .navClosedUser
                                                                : this.state
                                                                      .navClosed),
                                                            ...props,
                                                        },
                                                    )
                                                }
                                            />
                                            <Route
                                                render={(props) =>
                                                    this.getComponent(
                                                        !(
                                                            hasUser &&
                                                            hasUser["verified?"]
                                                        ),
                                                        PageNotFound,
                                                        props,
                                                    )
                                                }
                                            />
                                        </Switch>
                                    </Suspense>
                                    <CustomSnackbar
                                        variant="error"
                                        showSnackbar={this.state.error}
                                        message={this.state.error}
                                        onClose={this.clearError}
                                    />
                                    <Modal
                                        open={Boolean(
                                            this.state.outstandingAgreements
                                                .length,
                                        )}
                                        aria-labelledby="agreements-modal"
                                    >
                                        <div className="agreements-modal">
                                            <div className="modal-title">
                                                <h3
                                                    style={{
                                                        alignSelf: "center",
                                                        fontSize: "20px",
                                                        fontFamily:
                                                            "Montserrat-SemiBold",
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    We've updated our terms
                                                </h3>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        paddingLeft: "20px",
                                                        paddingRight: "20px",
                                                        paddingTop: "20px",
                                                    }}
                                                >
                                                    {this.state.outstandingAgreements.map(
                                                        (agreement) => (
                                                            <>
                                                                <p
                                                                    className={
                                                                        "h4"
                                                                    }
                                                                    style={{
                                                                        textAlign:
                                                                            "left",
                                                                    }}
                                                                >
                                                                    {
                                                                        agreement.title
                                                                    }
                                                                </p>
                                                                <ReactMarkdown
                                                                    style={{
                                                                        textAlign:
                                                                            "left",
                                                                    }}
                                                                >
                                                                    {
                                                                        agreement.content
                                                                    }
                                                                </ReactMarkdown>
                                                                <FormControlLabel
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={
                                                                                this
                                                                                    .state
                                                                                    .agreementVersions[
                                                                                    toCamelCase(
                                                                                        agreement.permalink,
                                                                                    )
                                                                                ]
                                                                            }
                                                                            onChange={(
                                                                                event,
                                                                            ) =>
                                                                                this.changeAgreementVersion(
                                                                                    toCamelCase(
                                                                                        agreement.permalink,
                                                                                    ),
                                                                                    event
                                                                                        .currentTarget
                                                                                        .checked
                                                                                        ? agreement.version
                                                                                        : null,
                                                                                )
                                                                            }
                                                                            color="primary"
                                                                            name={`${agreement.permalink}_check`}
                                                                        />
                                                                    }
                                                                    label={
                                                                        <p
                                                                            style={{
                                                                                margin: "auto",
                                                                            }}
                                                                        >
                                                                            I
                                                                            have
                                                                            read
                                                                            and
                                                                            understood
                                                                        </p>
                                                                    }
                                                                />
                                                            </>
                                                        ),
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        marginRight: "16px",
                                                    }}
                                                    className="modal-buttons"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color={"primary"}
                                                        style={{
                                                            borderRadius:
                                                                "12px",
                                                            marginBottom:
                                                                "16px",
                                                        }}
                                                        onClick={() =>
                                                            this.submitAgreements()
                                                        }
                                                        loading={
                                                            this.state
                                                                .loadingAgreements
                                                        }
                                                        disabled={
                                                            Object.keys(
                                                                this.state
                                                                    .agreementVersions,
                                                            ).length <
                                                            this.state
                                                                .outstandingAgreements
                                                                .length
                                                        }
                                                    >
                                                        Continue
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </>
                            </QueryParamProvider>
                        </BrowserRouter>
                    </SamlProvider>
                </ThemeProvider>
            </SmartesterAPIProvider>
        );
    }

    render() {
        return this.state.isLoading ? <Loader /> : this.renderRoutes();
    }
}

export default App;
