export const DEFAULT_HOST = process.env.REACT_APP_DEFAULT_HOST;
export const BASE_PATH = process.env.REACT_APP_BASE_PATH;

export const SAML_ENABLED = process.env.REACT_APP_SAML_ENABLED == "true";
export const SAML_SUPPORT_EMAIL = process.env.REACT_APP_SAML_SUPPORT_EMAIL;
export const SAML_SUPPORT_PHONE = process.env.REACT_APP_SAML_SUPPORT_PHONE;

export const SUPPORT_EMAIL =
    process.env.REACT_APP_SUPPORT_EMAIL || "support@smartester.com";
export const SUPPORT_PHONE =
    process.env.REACT_APP_SUPPORT_PHONE || "01225 860806";

export const EXPORT_TIMEOUT_SECONDS = 60;
export const EXPORT_MIME_TYPES = {
    csv: "text/csv",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const CALIBRATION_REQUEST_URL =
    "https://smartester.atlassian.net/servicedesk/customer/portal/4/group/4/create/10161";

export const DEFAULT_PRESSURE_TEST_ANALYSER_OPTIONS = {
    secondDifferentialNoiseWindow: 0.6,
    cumulativeSecondDifferentialNoiseWindow: 1.4,
    maximumRootMeanSquaredError: 0.08,
    unstableSecondDifferentialNoiseWindow: 0.6,
    minimumStablePeriodLength: 5,
    minimumUnstablePeriodLength: 3,
    bestStableDetectionPeriodSelectionAlgorithm: "longest",
};

export default {
    DEFAULT_HOST,
    BASE_PATH,
    EXPORT_TIMEOUT_SECONDS,
    EXPORT_MIME_TYPES,
    CALIBRATION_REQUEST_URL,
    DEFAULT_PRESSURE_TEST_ANALYSER_OPTIONS,
    SUPPORT_EMAIL,
    SUPPORT_PHONE,
    SAML_ENABLED,
    SAML_SUPPORT_EMAIL,
    SAML_SUPPORT_PHONE,
};
